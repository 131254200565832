import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CrudService } from './crud.service';
import { Clients } from '../models/clients';
import { Company } from '../models/company';

@Injectable({
  providedIn: 'root'
})

export class CompanyService extends CrudService<Company, number> {

  constructor(protected _http: HttpClient) {
    super(_http, `${environment.baseUrl}/company`);
  }

}



